@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes gradientBG {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes glow {
    0% {
        box-shadow: 0 0 5px rgba(120, 120, 120, 0.3);
    }
    50% {
        box-shadow: 0 0 15px rgba(120, 120, 120, 0.5);
    }
    100% {
        box-shadow: 0 0 5px rgba(120, 120, 120, 0.3);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotateReverse {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.loading-screen {
    animation: fadeIn 0.5s ease-in-out, gradientBG 15s ease infinite;
    background: linear-gradient(-45deg, #f5f5f5, #e8e8e8, #dadada, #cccccc);
    background-size: 400% 400%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}

.loading-circle {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid rgba(120, 120, 120, 0.1);
    border-top: 2px solid #787878;
    animation: rotate 8s linear infinite;
}

.loading-circle-outer {
    position: absolute;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 1px solid rgba(120, 120, 120, 0.05);
    border-bottom: 1px solid rgba(120, 120, 120, 0.4);
    animation: rotateReverse 12s linear infinite;
}

.loading-spinner {
    animation: pulse 1.5s infinite ease-in-out, glow 2s infinite ease-in-out;
    color: #787878 !important;
    width: 4rem !important;
    height: 4rem !important;
    border-width: 0.25rem;
    border-radius: 50%;
    position: relative;
    z-index: 2;
}

.loading-text {
    animation: fadeIn 1s ease-in-out;
    color: #555555 !important;
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    text-shadow: 0px 1px 2px rgba(255, 255, 255, 0.7);
    letter-spacing: 0.5px;
    position: relative;
    z-index: 2;
    margin-top: 1rem;
} 