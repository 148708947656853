html body {
	height: 100%;
	background-color: #f5f5f5;
	font-family: 'Poppins', Tahoma, Geneva, Verdana, sans-serif;
}

body::-webkit-scrollbar,
body::-webkit-scrollbar-horizontal {
	width: 10px;
	height: 10px;
}

.table-responsive::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar-horizontal {
	width: 10px;
	height: 10px;
}

a {
	/* color: #000 !important; */
	text-decoration: none !important;
}

.navbar {
	box-shadow: 0px 4px 39px rgba(0, 0, 0, 0.25);
	position: sticky;
	z-index: 999;
	top: 0;
	left: 0;
}

.react-calendar {
	width: 100% !important;
	border: none !important;
	background: none !important;
	font-family: 'Poppins', Helvetica, sans-serif !important;
	text-align: center;
}

.react-calendar__navigation button {
	font-size: 14px;
	height: 30px;
	width: 30px;
	color: #ffffff;
	margin-top: 10px !important;
	background: none;
	border: none;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: #c5c2c2 !important;
	color: #181818 !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background-color: #fff !important;
	color: #181818 !important;
}

.react-calendar__tile--now {
	background-color: #fff !important;
	color: #1b1b1b !important;
}

.react-calendar__tile--active {
	background-color: #fff !important;
	color: #1b1b1b !important;
}

.react-calendar__viewContainer {
	background: #ffffff !important;
	margin-top: -15px !important;
}

.react-calendar__navigation__label {
	color: #ffffff;
}

.react-calendar__tile--now {
	background: #e7e7e7;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background: #c3c3c3;
}

.react-calendar__tile {
	border: 1px solid #cdcdcd !important;
}

.event-button {
	background: #d32121;
	border-radius: 10px;
	color: #ffffff;
	font-size: 0.9rem;
	margin-top: 5px;
	padding: 5px;
	width: 100%;
}


.event-button:hover {
	background: #e73f3f;
}

.sidebar-content {
	position: sticky;
	z-index: 2;
	padding-right: 100px;
}

.sidenav {
	position: fixed;
	z-index: 1;
	top: calc(50% + 46.5px);
	left: 0px;
	width: 100px;
	transform: translate(0%, -50%);
	border-radius: 0px 35px 35px 0px;
	box-shadow: 0px 0px 47px rgba(0, 0, 0, 0.1);
}

.cursor-pointer {
	cursor: pointer !important;
}

.cursor-disabled {
	cursor: not-allowed !important;
	pointer-events: none !important;
}

.cursor-default {
	cursor: default !important;
}

.fw-500 {
	font-weight: 500;
}

.fs-7 {
	font-size: 0.8rem !important;
}

.fs-8 {
	font-size: 0.65rem !important;
}

.fs-9 {
	font-size: 0.5rem !important;
}

.text-justify {
	text-align: justify;
}

.resize-none {
	resize: none;
}

.min-table {
	min-width: 767px;
}

.m-5px {
	margin: 5px;
}

.p-12px {
	padding: 12px;
}

.p-14px {
	padding: 14px;
}

.page-content {
	padding-left: 146px !important;
	padding-right: 46px !important;
}

.padding-nav-bar {
	padding-left: 146px !important;
	padding-right: 146px !important;
}


/* start fade-in */
.fade-in {
	-webkit-animation: fadeIn 0.5s ease-in-out;
	-moz-animation: fadeIn 0.5s ease-in-out;
	-o-animation: fadeIn 0.5s ease-in-out;
	animation: fadeIn 0.5s ease-in-out;
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

/* end fade-in */

.tooltip-container {
	position: relative;
	display: inline-block;
}

.tooltip-text {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	padding: 2px 10px 2px 10px;
	font-size: 14px;
	border-radius: 6px;
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 150%;
	margin-left: -20px;
	transform: translateY(-50%);
}

.tooltip-text-top {
	visibility: hidden;
	color: #fff;
	text-align: center;
	padding: 2px 10px 2px 10px;
	font-size: 14px;
	border-radius: 6px;
	position: absolute;
	z-index: 1;
	width: 100%;
	margin-top: -60px;
	/* ajusta distância do topo */
	left: 50%;
	transform: translateX(-54%);
}

/* Mostra o tooltip quando o container é passado por cima */
.tooltip-container:hover .tooltip-text,
.tooltip-container:hover .tooltip-text-top {
	visibility: visible;
}


@media (max-width: 768px) {
	.sidebar-content {
		padding-right: 0;
	}

	.sidenav {
		bottom: 0;
		width: 100%;
		top: unset;
		transform: unset;
		justify-content: space-between;
		border-radius: 20px 20px 0 0;
		margin-bottom: 0px;
		height: 60px !important;
		position: fixed;
		padding: 0 0.5rem !important;
		z-index: 1050;
	}
	
	.sidebar-content {
		padding-right: 0;
		height: auto;
		width: 100%;
		position: fixed;
		bottom: 0;
		z-index: 1050;
	}
	
	.menu-item-container {
		padding: 0.5rem;
		margin: 0 0.2rem;
	}
	
	.icon-container {
		font-size: 1.3rem;
		margin-bottom: 0;
	}
	
	.notification-badge {
		top: -5px;
		right: -5px;
		padding: 0.1rem 0.3rem;
		font-size: 0.65rem;
	}
}

@media (max-width: 576px) {
	.sidenav {
		padding: 0 0.2rem !important;
	}
	
	.menu-item-container {
		padding: 0.4rem 0.3rem;
		margin: 0 0.1rem;
	}
	
	.icon-container {
		font-size: 1.2rem;
	}
}

.bg-red-light {
	background: rgba(235, 38, 44, 0.4) !important;
}

.bg-grey-light {
	background: rgb(246 246 246) !important;
	border: none !important;
}

.bg-grey {
	background: rgba(71, 71, 71, 0.4) !important;
}

.progress {
	width: 300px;
}

.table-full-width {
	width: 100%;
}

.table-header {
	position: fixed;
	width: 92vw;
	margin-top: -5px;
}

@media (max-width: 768px) {
	.progress {
		width: 200px;
	}

	.table-full-width {
		width: 93vw;
	}

	.table-header {
		position: fixed;
		width: 87vw;
	}
}

@media (max-width: 495px) {

	.table-full-width {
		width: 87vw;
	}

}

button.btn.btn-outline-primary.text-primary:hover {
	color: #ffffff !important;
}

.inner-addon {
	position: relative;
}

.right-addon .bi {
	right: 0px;
}

.inner-addon .bi {
	position: absolute;
	pointer-events: none;
	padding: 18px;
}

.right-addon input {
	padding-right: 45px !important;
}

.picture {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	overflow: hidden;
}

.button-icon {
	border: 1px solid #233734;
	width: 36px;
	height: 36px;
}

input[type="text"],
input[type="search"],
input[type="color"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="button"],
input[type="submit"],
input[type="date"],
input[type="datetime-local"],
select {
	height: 50px;
}

input[type="file"] {
	display: none;
}

.label-input {
	padding: 16px 0rem !important;
	cursor: pointer;
}

.label-input-simple {
	padding: 12px 0rem !important;
	cursor: pointer;
}

.red-bg {
	background-color: #233734;
}

.icon-large {
	font-size: 2.5em;
}

.card-upload {
	height: 70px;
	background: #ededed !important;
	border: none !important;
}

.border-none {
	border: none !important;
}

.screen-max-height {
	min-height: calc(100vh - 95px - 6rem);
}

.gradient {
	background: linear-gradient(180deg, #0e0e0e 0%, rgb(0 0 0 / 34%) 100%);
}

.card-flag {
	max-height: 300px;
	min-height: 300px;
}

.card-flag-aluno {
	max-height: 200px;
}

.card-img-banner {
	height: 300px;
	object-fit: cover;
}

.card-img-edit {
	height: 200px;
	object-fit: cover;
}

.card-img-question-edit {
	height: 200px;
	object-fit: contain;
}

.card-flag .info {
	position: absolute;
	bottom: 0;
}

.card-flag .info .opacity {
	background: #233734;
}

.link:hover {
	color: #1b1b1b !important;
	text-decoration: underline !important;
}

.link-white:hover {
	color: #fafafa !important;
}


.box-card-curso {
	width: 100% !important;
	height: 120px;
}

.back-image {
	/* background-image: url('../public/assets/recovery.png'); */
	background-size: 4rem;
	background-position: bottom right;
	background-repeat: no-repeat;
}

.video {
	height: 600px;
}

.space-grey {
	height: 80px;
}

.img-banner {
	width: 100% !important;
	/* height: 25vh; */
	object-fit: cover;
}

.img-banner-aluno {
	width: 100% !important;
	height: 35vh;
	object-fit: cover;
}

.img-box {
	height: 180px;
	object-fit: cover;
}

.border-bottom-radius-0 {
	border-bottom-right-radius: 0rem !important;
	border-bottom-left-radius: 0rem !important;
}

@media (max-height: 768px) {
	.img-banner {
		height: 30vh !important;
	}

	.img-banner-aluno {
		height: 28vh !important;
	}

	.video {
		height: 500px;
	}
}


.container-fluid .row {
	height: 100%;
}

.login-container {
	height: calc(100vh - 93px);
	/* background-image: url("../public/assets/bg-login.png"); */
	background-size: contain;
	background-position: center right;
	background-repeat: no-repeat;
}

@media (max-width: 1199px) {
	.login-container {
		background: none !important;
	}
}

.toast-header {
	justify-content: space-between !important;
	background-color: #f3f3f3 !important;
}

.btn-height-58px {
	height: 58px !important;
}

.text-center-overlay {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	width: 100%;
	/* Certifique-se de que o texto ocupe toda a largura do container */
}

.doc_aula {
	height: 88vh;
}

.input-group-text {
	height: 50px;
	margin-top: 0px;
	min-width: 45px;
	justify-content: center;
}

.bg-none {
	background: none !important;
}

.cursor-disabled {
	cursor: not-allowed;
	pointer-events: all !important;
}

.chat-messages {
	height: calc(100vh - 320px);
}

.text-pretty {
	text-wrap: pretty;
}

.text-balance {
	text-wrap: balance;
}

.btn-none {
	background: none !important;
	border: none !important;
	padding: 0 !important;
	margin: 0 !important;
	font: inherit !important;
	cursor: pointer !important;
	outline: none !important;
	box-shadow: none !important;
	text-align: inherit !important;
	text-decoration: none !important;
}

.btn-none:hover,
.btn-none:focus,
.btn-none:active,
.btn-none:visited {
	background: none !important;
	border: none !important;
	text-decoration: none !important;
	box-shadow: none !important;
	outline: none !important;
}

.box-card-curso {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

/* Estilos para linhas ímpares (odd) */
.odd-row {
	background-color: #ffffff !important;
	/* cinza mais claro, por exemplo */
}

/* Estilos para linhas pares (even) */
.even-row {
	background-color: #f5f2f2 !important;
	/* cinza claro, por exemplo */
}

/* CSS para tornar a tabela scrollable horizontalmente */
.scrollable-table {
	overflow-x: auto;
}

/* CSS para garantir que os inputs e botões tenham uma largura mínima */
.scrollable-table input.form-control {
	min-width: 200px;
	/* ou o valor que preferir */
}

.scrollable-table button.btn {
	min-width: 120px;
	/* ou o valor que preferir */
}

.perfil-foto {
	width: 7rem;
	height: 7rem;
	border-radius: 50%;
	object-fit: cover;
}

.perfil-foto:hover {
	opacity: 0.5;
	cursor: pointer;
	transition: all 0.3s ease;
}

.bg-muted {
	background: #f8f8f8 !important;
}

.container-default {
	max-width: 110rem;
	width: 100%;
	padding-left: 93px;
}

@media (max-width: 1800px) {
	.container-default {
		padding-left: 100px;
	}
}

@media (max-width: 767px) {
	.container-default {
		padding-left: 0px;
	}
}

.card-flag-grupo {
	height: 150px !important;
}

.report-card .card-body {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.report-card .rounded-circle {
	width: 50px;
	height: 50px;
}

/* Adicione isso ao seu arquivo CSS */
@keyframes blink {

	0%,
	100% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}
}

.circle-icon {
	position: absolute;
	top: 1px;
	right: -8px;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: rgb(48, 211, 7);
	animation: blink 1.5s infinite;
}

/* Certifique-se de que o contêiner pai tenha position: relative */
.container {
	position: relative;
}

.loader {
	width: 50px;
	aspect-ratio: 1;
	border-radius: 50%;
	background:
		radial-gradient(farthest-side, #FF8931 94%, #0000) top/8px 8px no-repeat,
		conic-gradient(#0000 30%, #FF8931);
	-webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
	mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
	animation: l13 1s infinite linear;
}

@keyframes l13 {
	100% {
		transform: rotate(1turn)
	}
}

/* Header Styles */
.header-container {
	position: fixed;
	top: 0;
	right: 0;
	left: 100px;
	z-index: 999;
	transition: all 0.3s ease;
	margin: 1rem;
	border-radius: 15px;
}

.header-container .navbar {
	background: rgba(var(--bs-primary-rgb), 1);
	border-radius: 15px;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	border: 1px solid rgba(255, 255, 255, 0.1);
	transition: all 0.3s ease;
	padding: 0.5rem 0;
}

.header-container.scrolled .navbar {
	background: rgba(var(--bs-primary-rgb), 0.5);
	backdrop-filter: blur(15px);
	-webkit-backdrop-filter: blur(15px);
	box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
	border: 1px solid rgba(255, 255, 255, 0.18);
}

.header-container .navbar-brand img {
	height: 35px;
	width: auto;
}

@media (max-width: 767px) {
	.header-container {
		left: 60px;
		margin: 0.5rem;
		right: 0.5rem;
	}

	.header-container .navbar-brand img {
		height: 25px;
	}
	
	.header-container .navbar {
		padding: 0.4rem 0;
	}
}

@media (max-width: 576px) {
	.header-container {
		left: 5px;
		margin: 0.3rem;
		right: 0.3rem;
	}
	
	.header-container .navbar {
		padding: 0.3rem 0;
		border-radius: 10px;
	}
}

@media (max-width: 767px) {
	.header-container {
		left: 5px;
		margin: 0.3rem;
		right: 0.3rem;
	}
	
	.header-container .navbar {
		padding: 0.3rem 0;
		border-radius: 10px;
	}
}

.header-container .dropdown-menu {
	background: rgba(255, 255, 255, 1);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 10px;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
	.header-container .dropdown-menu {
		width: auto;
		min-width: 240px;
		max-width: 95vw;
		font-size: 0.9rem;
	}
}

/* Layout Styles */
.layout-container {
	display: flex;
	min-height: 100vh;
	background: linear-gradient(135deg, #f5f7fa 0%, #e4e9f0 100%);
}

.main-content {
	flex: 1;
	margin-left: 100px;
	padding: 5rem 1rem 1rem 1rem;
	width: calc(100% - 100px);
	position: relative;
	z-index: 1;
}

@media (max-width: 767px) {
	.main-content {
		margin-left: 60px;
		width: calc(100% - 60px);
		padding: 4.5rem 0.5rem 4.5rem 0.5rem;
	}
}

@media (max-width: 576px) {
	.main-content {
		margin-left: 0;
		width: 100%;
		padding: 4.5rem 0.5rem 5rem 0.5rem;
	}
}

/* Sidebar Styles */
.sidebar-content {
	position: fixed;
	z-index: 1000;
	transition: all 0.3s ease;
}

.sidenav {
	height: 100%;
	padding: 1rem 0.5rem;
	background: var(--bs-primary) !important;
}

.nav-brand {
	width: 100%;
	padding: 1rem;
	display: flex;
	justify-content: center;
}

.nav-logo {
	max-width: 80%;
	height: auto;
	filter: brightness(0) invert(1);
}

.sidebar-logo {
	height: 30px;
	filter: brightness(0) invert(1);
}

.sidebar-link {
	text-decoration: none;
	color: rgba(255, 255, 255, 0.8);
	width: 100%;
	transition: all 0.3s ease;
}

.sidebar-link:hover {
	color: white;
}

.menu-item-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0.3rem;
	margin: 0.3rem 0;
	border-radius: 10px;
	transition: all 0.3s ease;
}

.menu-item-container:hover {
	background: rgba(255, 255, 255, 0.1);
	transform: translateY(-2px);
}

.menu-item-active {
	background: rgba(255, 255, 255, 0.2);
	color: white;
}

.menu-item-active:hover {
	background: rgba(255, 255, 255, 0.2);
}

.icon-container {
	font-size: 1.5rem;
	margin-bottom: 0.3rem;
}

.menu-label {
	font-size: 0.75rem;
	display: none;
	text-align: center;
}

.notification-badge {
	position: absolute;
	top: -8px;
	right: -8px;
	background: #dc3545;
	color: white;
	border-radius: 50%;
	padding: 0.1rem 0.5rem;
	font-size: 0.75rem;
	border: 2px solid var(--bs-primary);
}

@media (min-width: 768px) {
	.menu-label {
		display: block;
	}

	.sidebar-content {
		width: 100px;
	}

	.menu-item-container:hover {
		background: rgba(255, 255, 255, 0.1);
	}
}

@media (max-width: 767px) {
	.sidenav {
		height: 10%;
	}

	.expanded .menu-label {
		display: block;
	}

	.expanded .sidenav {
		padding: 1rem 2rem;
	}

	.menu-item-container {
		flex-direction: row;
		justify-content: center;
		gap: 0;
	}

	.icon-container {
		margin-bottom: 0.3rem;
	}

	.expanded .menu-item-container {
		flex-direction: row;
		justify-content: flex-start;
		gap: 1rem;
	}

	.expanded .icon-container {
		margin-bottom: 0;
	}
}

.btn-outline-success:hover {
	color: #fff !important
}