:root {
    /* Cores padrão */
    --bs-primary: #fff;
    --bs-primary-rgb: 255, 255, 255;
    --bs-secondary: #fff;
    --bs-secondary-rgb: 255, 255, 255;
    
    /* Estas variáveis serão sobrescritas dinamicamente pelo AjustesContext */
}

/* Sobrescrever classes do Bootstrap com as variáveis CSS */
.btn-primary {
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background-color: var(--bs-primary) !important;
    filter: brightness(90%);
    border-color: var(--bs-primary) !important;
}

.btn-outline-primary {
    color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
    color: #ffffff !important;
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
}

.text-primary {
    color: var(--bs-primary) !important;
}

.bg-primary {
    background-color: var(--bs-primary) !important;
}

.border-primary {
    border-color: var(--bs-primary) !important;
}

.btn-secondary {
    background-color: var(--bs-secondary) !important;
    border-color: var(--bs-secondary) !important;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
    background-color: var(--bs-secondary) !important;
    filter: brightness(90%);
    border-color: var(--bs-secondary) !important;
}

.text-secondary {
    color: var(--bs-secondary) !important;
}

.bg-secondary {
    background-color: var(--bs-secondary) !important;
}

.border-secondary {
    border-color: var(--bs-secondary) !important;
}

/* Outros elementos que usam as cores primárias */
.form-control:focus {
    border-color: var(--bs-primary);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
}

.form-check-input:checked {
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
}

.nav-link.active {
    color: var(--bs-primary) !important;
}

.page-link {
    color: var(--bs-primary) !important;
}

.page-item.active .page-link {
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
}

/* Elementos de navegação */
.header-container {
    background-color: var(--bs-primary) !important;
}

.sidebar .nav-link:hover {
    background-color: rgba(var(--bs-primary-rgb), 0.1) !important;
}

.sidebar .nav-link.active {
    background-color: var(--bs-primary) !important;
} 

body::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb:horizontal {
	background-color: var(--bs-primary);
	border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb:horizontal {
	background-color: var(--bs-primary);
	border-radius: 10px;
}

.react-calendar__navigation button:hover {
	font-size: 14px;
	background: var(--bs-secondary) !important;
	color: #ffffff !important;
	height: 30px;
}

.react-calendar--selectRange .react-calendar__tile--hover {
	background-color: var(--bs-primary) !important;
	color: #ffffff !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
	background-color: var(--bs-secondary) !important;
	color: #ffffff !important;
	border-radius: 10px;
}


.react-calendar__navigation {
	background: var(--bs-primary) !important;
	height: 65px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	justify-content: center;
	display: flex;
}

.dropdown-item.active, .dropdown-item:active {
    text-decoration: none;
    background-color: var(--bs-primary) !important;
}