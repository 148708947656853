.lista_grupo .card-header h2 {
	font-size: 2rem;
	font-weight: bold;
	margin: 0;
}

.lista_grupo .card-header h4 {
	font-size: 1.5rem;
	font-weight: normal;
	margin-top: 10px;
	color: #6c757d;
}

.lista_grupo .card-header h5 {
	font-size: 1.2rem;
	font-weight: normal;
	margin-top: 10px;
	color: #6c757d;
}

.lista_grupo .card-header p {
	margin-top: 10px;
	color: #6c757d;
}

.lista_grupo .table th,
.lista_grupo .table td {
	vertical-align: middle;
}

.lista_grupo .table .thead-dark th {
	background-color: #343a40;
	color: white;
}

.lista_grupo .search-bar {
	margin-bottom: 20px;
}

.lista_grupo .card {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.lista_grupo .card-body {
	padding: 2rem;
}

.lista_grupo .card-header {
	background-color: #f8f9fa;
	padding: 2rem;
	border-bottom: 1px solid #dee2e6;
}

.lista_grupo .search-input {
	width: 100%;
	padding: 0.5rem;
	margin-bottom: 1rem;
	border-radius: 5px;
	border: 1px solid #ced4da;
}

@media (max-width: 768px) {
	.lista_grupo .card-header h2 {
		font-size: 1.5rem;
	}

	.lista_grupo .card-header h4,
	.lista_grupo .card-header h5,
	.lista_grupo .card-header p {
		font-size: 1rem;
	}
}