@import '../../node_modules/bootstrap/scss/functions';


@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
// Importar o restante do Bootstrap
@import "~bootstrap/scss/bootstrap";

// Utilitários de responsividade para dispositivos móveis
.fs-sm-1 {
    @media (max-width: 576px) {
        font-size: $h1-font-size * 0.7 !important;
    }
}

.fs-sm-2 {
    @media (max-width: 576px) {
        font-size: $h2-font-size * 0.7 !important;
    }
}

.fs-sm-3 {
    @media (max-width: 576px) {
        font-size: $h3-font-size * 0.7 !important;
    }
}

.fs-sm-4 {
    @media (max-width: 576px) {
        font-size: $h4-font-size * 0.7 !important;
    }
}

.fs-sm-5 {
    @media (max-width: 576px) {
        font-size: $h5-font-size * 0.8 !important;
    }
}

.fs-sm-6 {
    @media (max-width: 576px) {
        font-size: $h6-font-size * 0.8 !important;
    }
}

// Classe para melhorar espaçamento em dispositivos móveis
.px-xs-1 {
    @media (max-width: 576px) {
        padding-left: $spacer * 0.25 !important;
        padding-right: $spacer * 0.25 !important;
    }
}

.px-xs-2 {
    @media (max-width: 576px) {
        padding-left: $spacer * 0.5 !important;
        padding-right: $spacer * 0.5 !important;
    }
}

.py-xs-1 {
    @media (max-width: 576px) {
        padding-top: $spacer * 0.25 !important;
        padding-bottom: $spacer * 0.25 !important;
    }
}

.py-xs-2 {
    @media (max-width: 576px) {
        padding-top: $spacer * 0.5 !important;
        padding-bottom: $spacer * 0.5 !important;
    }
}

.gap-xs-1 {
    @media (max-width: 576px) {
        gap: $spacer * 0.25 !important;
    }
}

.gap-xs-2 {
    @media (max-width: 576px) {
        gap: $spacer * 0.5 !important;
    }
}

.menu-item:hover {
    color: #fff;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.menu-item-active i {
    color: #fff;
}

// Classes
.bg-gray-100 {
    background-color: $gray-100 !important;
}

.bg-gray-200 {
    background-color: $gray-200 !important;
}

.bg-gray-300 {
    background-color: $gray-300 !important;
}

.bg-gray-400 {
    background-color: $gray-400 !important;
}

.bg-gray-500 {
    background-color: $gray-500 !important;
}

.bg-gray-600 {
    background-color: $gray-600 !important;
}

.bg-gray-700 {
    background-color: $gray-700 !important;
}

.bg-gray-800 {
    background-color: $gray-800 !important;
}

.bg-gray-900 {
    background-color: $gray-900 !important;
}

/* Margins Top Custom */
@media (max-width: 576px) {
    .mt-xs-1 {
        margin-top: 0.25rem !important;
    }

    .mt-xs-2 {
        margin-top: 0.5rem !important;
    }

    .mt-xs-3 {
        margin-top: 1rem !important;
    }

    .mt-xs-4 {
        margin-top: 1.5rem !important;
    }

    .mt-xs-5 {
        margin-top: 3rem !important;
    }
}

@media (min-width: 576px) {
    .mt-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mt-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mt-sm-3 {
        margin-top: 1rem !important;
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important;
    }

    .mt-sm-5 {
        margin-top: 3rem !important;
    }
}

@media (min-width: 768px) {
    .mt-md-1 {
        margin-top: 0.25rem !important;
    }

    .mt-md-2 {
        margin-top: 0.5rem !important;
    }

    .mt-md-3 {
        margin-top: 1rem !important;
    }

    .mt-md-4 {
        margin-top: 1.5rem !important;
    }

    .mt-md-5 {
        margin-top: 3rem !important;
    }
}

@media (min-width: 992px) {
    .mt-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mt-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mt-lg-3 {
        margin-top: 1rem !important;
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mt-lg-5 {
        margin-top: 3rem !important;
    }
}

@media (min-width: 1200px) {
    .mt-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mt-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mt-xl-3 {
        margin-top: 1rem !important;
    }

    .mt-xl-4 {
        margin-top: 1.5rem !important;
    }

    .mt-xl-5 {
        margin-top: 3rem !important;
    }
}

/* Margins Bottom Custom */
@media (max-width: 576px) {
    .mb-xs-1 {
        margin-bottom: 0.25rem !important;
    }

    .mb-xs-2 {
        margin-bottom: 0.5rem !important;
    }

    .mb-xs-3 {
        margin-bottom: 1rem !important;
    }

    .mb-xs-4 {
        margin-bottom: 1.5rem !important;
    }

    .mb-xs-5 {
        margin-bottom: 3rem !important;
    }
}

@media (min-width: 576px) {
    .mb-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .mb-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important;
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .mb-sm-5 {
        margin-bottom: 3rem !important;
    }
}

@media (min-width: 768px) {
    .mb-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .mb-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .mb-md-3 {
        margin-bottom: 1rem !important;
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .mb-md-5 {
        margin-bottom: 3rem !important;
    }
}

@media (min-width: 992px) {
    .mb-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .mb-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important;
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .mb-lg-5 {
        margin-bottom: 3rem !important;
    }
}

@media (min-width: 1200px) {
    .mb-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .mb-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .mb-xl-3 {
        margin-bottom: 1rem !important;
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .mb-xl-5 {
        margin-bottom: 3rem !important;
    }
}

/* Margins Start Custom */
@media (max-width: 576px) {
    .ms-xs-1 {
        margin-left: 0.25rem !important;
    }

    .ms-xs-2 {
        margin-left: 0.5rem !important;
    }

    .ms-xs-3 {
        margin-left: 1rem !important;
    }

    .ms-xs-4 {
        margin-left: 1.5rem !important;
    }

    .ms-xs-5 {
        margin-left: 3rem !important;
    }
}

@media (min-width: 576px) {
    .ms-sm-1 {
        margin-left: 0.25rem !important;
    }

    .ms-sm-2 {
        margin-left: 0.5rem !important;
    }

    .ms-sm-3 {
        margin-left: 1rem !important;
    }

    .ms-sm-4 {
        margin-left: 1.5rem !important;
    }

    .ms-sm-5 {
        margin-left: 3rem !important;
    }
}

@media (min-width: 768px) {
    .ms-md-1 {
        margin-left: 0.25rem !important;
    }

    .ms-md-2 {
        margin-left: 0.5rem !important;
    }

    .ms-md-3 {
        margin-left: 1rem !important;
    }

    .ms-md-4 {
        margin-left: 1.5rem !important;
    }

    .ms-md-5 {
        margin-left: 3rem !important;
    }
}

@media (min-width: 992px) {
    .ms-lg-1 {
        margin-left: 0.25rem !important;
    }

    .ms-lg-2 {
        margin-left: 0.5rem !important;
    }

    .ms-lg-3 {
        margin-left: 1rem !important;
    }

    .ms-lg-4 {
        margin-left: 1.5rem !important;
    }

    .ms-lg-5 {
        margin-left: 3rem !important;
    }
}

@media (min-width: 1200px) {
    .ms-xl-1 {
        margin-left: 0.25rem !important;
    }

    .ms-xl-2 {
        margin-left: 0.5rem !important;
    }

    .ms-xl-3 {
        margin-left: 1rem !important;
    }

    .ms-xl-4 {
        margin-left: 1.5rem !important;
    }

    .ms-xl-5 {
        margin-left: 3rem !important;
    }
}

/* Margins End Custom */
@media (max-width: 576px) {
    .me-xs-1 {
        margin-right: 0.25rem !important;
    }

    .me-xs-2 {
        margin-right: 0.5rem !important;
    }

    .me-xs-3 {
        margin-right: 1rem !important;
    }

    .me-xs-4 {
        margin-right: 1.5rem !important;
    }

    .me-xs-5 {
        margin-right: 3rem !important;
    }
}

@media (min-width: 576px) {
    .me-sm-1 {
        margin-right: 0.25rem !important;
    }

    .me-sm-2 {
        margin-right: 0.5rem !important;
    }

    .me-sm-3 {
        margin-right: 1rem !important;
    }

    .me-sm-4 {
        margin-right: 1.5rem !important;
    }

    .me-sm-5 {
        margin-right: 3rem !important;
    }
}

@media (min-width: 768px) {
    .me-md-1 {
        margin-right: 0.25rem !important;
    }

    .me-md-2 {
        margin-right: 0.5rem !important;
    }

    .me-md-3 {
        margin-right: 1rem !important;
    }

    .me-md-4 {
        margin-right: 1.5rem !important;
    }

    .me-md-5 {
        margin-right: 3rem !important;
    }
}

@media (min-width: 992px) {
    .me-lg-1 {
        margin-right: 0.25rem !important;
    }

    .me-lg-2 {
        margin-right: 0.5rem !important;
    }

    .me-lg-3 {
        margin-right: 1rem !important;
    }

    .me-lg-4 {
        margin-right: 1.5rem !important;
    }

    .me-lg-5 {
        margin-right: 3rem !important;
    }
}

@media (min-width: 1200px) {
    .me-xl-1 {
        margin-right: 0.25rem !important;
    }

    .me-xl-2 {
        margin-right: 0.5rem !important;
    }

    .me-xl-3 {
        margin-right: 1rem !important;
    }

    .me-xl-4 {
        margin-right: 1.5rem !important;
    }

    .me-xl-5 {
        margin-right: 3rem !important;
    }
}